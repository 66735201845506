import { format, parseISO } from 'date-fns'

export const parseData = (data) => {
  data = parseISO(data)
  data = format(data, 'dd/MM/yyyy')
  return data
}

export const maskCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, "")
  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2")
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")
  return cnpj
}

export const linhaDigitavel = (valor) => {
  return valor.replace(/^(\d{4})(\d+)/, '$1.$2')
    .replace(/^(\d{4}\.\d{6})(\d+)/, '$1 $2')
    .replace(/^(\d{4}\.\d{6} \d{5})(\d+)/, '$1.$2')
    .replace(/^(\d{4}\.\d{6} \d{5}\.\d{6})(\d+)/, '$1 $2')
    .replace(/^(\d{4}\.\d{6} \d{5}\.\d{6} \d{5})(\d+)/, '$1.$2')
    .replace(/^(\d{4}\.\d{6} \d{5}\.\d{6} \d{5}\.\d{6})(\d+)/, '$1 $2')
    .replace(/^(\d{4}\.\d{6} \d{5}\.\d{6} \d{5}\.\d{6} \d)(\d+)/, '$1 $2');
}
export const maskCPF = (cpf) => {
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  //cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  cpf = cpf.replace(/(\d{3})(\d{3})/g, "$1.$2-");
  return cpf;
}
export const maskCPF2 = (cpf) => {
  cpf = cpf.replace(/\D/g, "")
  if (cpf.length <= 11) {
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  }
  return cpf
}

export const maskTEL = (tel) => {
  if (tel.length >= 14) {
    tel = tel.replace(/\D/g, '')
    tel = tel.replace(/(\d{2})(\d)/, '($1) $2')
    tel = tel.replace(/(\d{5})(\d{4})/, '$1-$2')
  } else {
    tel = tel.replace(/\D/g, '')
    tel = tel.replace(/(\d{2})(\d)/, '($1) $2')
    tel = tel.replace(/(\d{4})(\d{4})/, '$1-$2')
  }
  return tel
}
export const maskCEP = (cep) => {
  cep = cep.replace(/\D/g, '')
  cep = cep.replace(/^(\d{5})(\d{3})+?$/, '$1-$2');

  return cep
}