import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Events from '../pages/Events';
import FormSubscriber from '../pages/FormSubscriber';
import EventDetails from '../pages/EventDetails';
import Contact from '../pages/Contact';
import Documents from '../pages/Documents';
import CheckinEvent from '../pages/Checkin';
import OldEvents from '../pages/OldEvents';
import ManagerUpdater from '../utils/ManagerUpdater';
import BoletoItau from '../pages/EventDetails/boleto';

export const Rotas = () => {
  const allowedPaths = ["", "apeprev"];
  const currentBase = window.location.pathname.split('/')[1];

  const baseName = allowedPaths.includes(currentBase) ? `/${currentBase}` : "/";


  return (
    <BrowserRouter basename={baseName}>
      <ManagerUpdater />
      <Routes>
          <Route index element={<Events />} />
          <Route path="subscribe">
            <Route path=":eventId" element={<FormSubscriber />} />
          </Route>
          <Route path="event">
            <Route path=":eventId" element={<EventDetails />} />
          </Route>
          <Route path="boleto">
            <Route path=":externalId" element={<BoletoItau />} />
          </Route>
          <Route path="checkin" >
            <Route path=":eventId" element={<CheckinEvent />} />
          </Route>
          <Route path="contact"  element={<Contact />} />
          <Route path="documents"  element={<Documents />} />
          <Route path="eventos-anteriores"  element={<OldEvents />} />
      </Routes>
    </BrowserRouter>
  )
};