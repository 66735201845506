import { useContext,  useMemo } from "react";
import { ManagerContext } from "../context/ManagerContext";
import { api, baseURL } from "../services/api";

const updateFavicon = (iconPath) => {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
        link.href = iconPath;
    } else {
        const newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = iconPath;
        document.head.appendChild(newLink);
    }
};

const ManagerUpdater = () => {
    const { manager, setManager } = useContext(ManagerContext);

    useMemo(() => {
        async function getManager() {
            const path =  window.location.pathname.split('/')[1];
            try {
                const { data } = await api.get('/configs/1?populate=logo');
                const logo = baseURL+data.data.attributes.logo.data.attributes.url;
                data.data.attributes.logo_url = logo;
                console.log(path)
                if (path == '' || path == undefined) {
                    updateFavicon("/favicons/agip.ico");
                } else if (path === "apeprev") {
                    updateFavicon("/favicons/apeprev.ico");
                }
                setManager(data.data.attributes);
                
            } catch (error) {
                console.log(error);
            }
        }
        if(manager.length > 0) return;
        getManager();

    }, [window.location.pathname]);

    return null;
};

export default ManagerUpdater;