import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { ArrowBack, Close as CloseIcon } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';


import AppNav from '../../components/appNav';

import Payer from './Payer';
import PayerInform from './PayerInform';
import TypeSubcriber from './TypeSubscribers';
import AddSubscriber from './AddSubscriber';
import FinsihRegistration from './FinsihRegistration';
import TablePayment from '../../components/TablePayment';

import { api } from '../../services/api'
import { useEvent } from '../../context/EventContext';
import { FormContext } from '../../context/FormContext';
import { OrderContext } from '../../context/OrderContext';
import { ManagerContext } from '../../context/ManagerContext';
import { isWithinInterval, parseISO } from 'date-fns'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Todos os direitos reservados © '}
      <Link color="inherit" href="https://softtechcloud.com.br/">
        SoftTech
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Pagador', 'Informações do Pagador', 'Tipo de Inscrição', 'Adicionar Inscritos', 'Revisar Inscrição'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Payer />;
    case 1:
      return <PayerInform />;
    case 2:
      return <TypeSubcriber />
    case 3:
      return <AddSubscriber />;
    case 4:
      return <FinsihRegistration />;
    default:
      throw new Error('Unknown step');
  }
}


export default function SubscriberPage() {
  const { manager } = React.useContext(ManagerContext);
  const { activeStep } = React.useContext(FormContext)
  let navigate = useNavigate()
  const { order, setOrder } = React.useContext(OrderContext)
  let { eventId } = useParams();
  const { events, setEvents } = useEvent()
  const [open, setOpen] = React.useState(false);
  const [payments, setPayments] = React.useState([])
  const [stateAlert, setStateAlert] = React.useState({ status: false, type: 'success', msg: '' });

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const columns = [
    { Header: "#", accessor: "icon", width: "100%" },
    { Header: "Boleto", accessor: "external_id", width: "100%" },
    { Header: "Baixar", accessor: "actions", width: "90%" },
  ];
  const handleView = ({ url }) => {
    window.open(url);
  }
  const handleClose = () => {
    setOpen(false);
  };
  async function getEventById(id) {
    try {
      const eventbyId = await api.get('/events/' + id)
      setEvents(...[eventbyId.data])
      setOrder({ ...order, event: eventId })
      let startDate = parseISO(eventbyId.data.start_subscription)
      let endDate = parseISO(eventbyId.data.end_subscription)
      let today = new Date();
      let result = isWithinInterval(today, { start: startDate, end: endDate })
      if (result === false) {
        setStateAlert({ status: true, type: 'error', msg: 'AS INSCRIÇÕES PARA O EVENTO AINDA NÃO ESTÃO ABERTAS!' })
        setTimeout(() => {
          handleBack()
        }, 2000)
        return
      }

    } catch (error) {
      console.log(error)
    }
  }


  function handleBack() {
    navigate(`/event/${eventId}`)
  }
  function handlePayment() {
    console.log(order)
    if (order[0].payments.length > 0) {
      console.log(order)
      setPayments(order[0].payments)
      setOpen(true)
    }
    if (order[0].payment_form === 'Boleto' && order[0].payments.length == 0) {
      window.open(order[0].payment_url)
    }
  }

  useLayoutEffect(() => {
    getEventById(eventId)
    //getInfoConfig()
    return () => {
      setStateAlert({ status: false, type: 'success', msg: '' })
    }

  }, [])

  return (
    <>
      <AppNav />
      <AppBar
        position="relative"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Snackbar
          open={stateAlert.status}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setStateAlert({ status: false, type: 'error', msg: '' })} >
          <Alert sx={{ width: '100%' }} severity={`${stateAlert.type}`}>
            {stateAlert.msg}
          </Alert>
        </Snackbar>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap textAlign={'center'}>
            {events.name || "Nome do Evento"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack direction={'row'} py={3} sx={{ alignItems: 'center' }}>
        <IconButton onClick={handleBack}>
          <ArrowBack color='error' />
        </IconButton>
        <Typography variant={'body'} color='error'>Sair</Typography>
      </Stack>
      <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
        <Dialog
          onClose={handleClose}
          fullWidth
          maxWidth={'lg'}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Boletos Aguardando Pagamento
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <TablePayment title='Boletos' variant="info" view={handleView} rows={payments} columns={columns} />
          </DialogContent>
        </Dialog>
        <Paper variant="outlined" sx={{ my: { xs: 2, md: 2 }, p: { xs: 2, md: 2 } }}>
          <Typography component="h1" variant="h4" align="center">
            Realize sua inscrição
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Obrigado pela sua inscrição.
                </Typography>
                <Typography variant="subtitle1">
                  {manager !== null ?
                    <>
                      {order[0].payment_form === 'Boleto' && <>
                        <p> Recebemos sua inscrição.<br />
                          A sua inscrição será confirmada mediante o pagamento do boleto.<br />
                          Estas informações de confirmação foram enviadas para o e-mail da instituição pagadora cadastrado no sistema de inscrição.</p>
                      </>}
                      {order[0].payment_form === 'Pix' &&
                        <p> Recebemos sua inscrição.<br />
                          A sua inscrição será confirmada mediante:<br />
                          - Pagamento e envio do comprovante de pagamento para o email <a href={'mailto:' + manager.email + ''}>{manager.email}</a> ou whatsapp <a href={`https://api.whatsapp.com/send?phone=55${manager.whatsapp}&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es`}>{manager.whatsapp}</a><br />
                          ou<br />
                          - Envio do empenho para o e-mail <a href={'mailto:' + manager.email + ''}>{manager.email}</a> ou whatsapp <a href={`https://api.whatsapp.com/send?phone=55${manager.whatsapp}&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es`}>{manager.whatsapp}</a><br />
                          <p>  A chave PIX é o <strong>CNPJ {manager.pix}</strong>, em nome da <strong>{manager.fantasy}</strong>.</p>
                          Estas informações de confirmação foram enviadas para  o e-mail da instituição pagadora cadastrado no sistema de inscrição.</p>}

                      {order[0].payment_form === 'Transação ou Depósito Bancário' && <>
                        <p>Recebemos sua inscrição.<br />
                          A sua inscrição será confirmada mediante:<br />
                          - Pagamento e envio do comprovante de pagamento para o email <a href={'mailto:' + manager.email + ''}>{manager.email}</a> ou whatsapp <a href={`https://api.whatsapp.com/send?phone=55${manager.whatsapp}&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es`}>{manager.whatsapp}</a><br />
                          ou<br />
                          - Envio do empenho para o e-mail <a href={'mailto:' + manager.email + ''}>{manager.email}</a> ou whatsapp <a href={`https://api.whatsapp.com/send?phone=55${manager.whatsapp}&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es`}>{manager.whatsapp}</a>.</p>

                        <p>A conta para depósito ou transferência bancária é em nome de <strong>{manager.social_reason.toUpperCase()} - {manager.fantasy}</strong><br />
                          <strong>
                            {manager.bank.toUpperCase()}<br />
                            AGÊNCIA {manager.agency}  <br />
                            OPERAÇÃO {manager.operation}  <br />
                            CONTA CORRENTE {manager.cc}<br />
                            CNPJ {manager.document}
                          </strong>
                        </p>
                        <p> Se preferir optar por PIX a chave é o <strong>CNPJ {manager.pix}</strong>, em nome da <strong>{manager.fantasy}</strong>.<br />
                          Estas informações de confirmação foram enviadas para  o e-mail da instituição pagadora cadastrado no sistema de inscrição.
                        </p>
                      </>
                      }
                      <p>
                        Solicitação de recibo ou nota fiscal através do email <a href={'mailto:' + manager.email + ''}>{manager.email}</a> ou whatsapp <a href={`https://api.whatsapp.com/send?phone=55${manager.whatsapp}&text=Ol%C3%A1,%20preciso%20de%20informa%C3%A7%C3%B5es`}>{manager.whatsapp}</a>
                      </p>
                    </>
                    :
                    null
                  }
                </Typography>
                <Button
                  size='medium'
                  variant="contained"
                  type={'button'}
                  color='success'
                  onClick={() => handleBack()}
                  sx={{ mt: 3, mr: 1 }}>
                  Voltar para evento
                </Button>
                {order[0].payment_form == "Boleto" &&
                  <Button
                    size='medium'
                    variant="contained"
                    type={'button'}
                    color='primary'
                    onClick={() => { handlePayment() }}
                    sx={{ mt: 3, mr: 1 }}>
                    Acessar boleto
                  </Button>
                }
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </>
  );
}