import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import AppNav from '../../components/appNav';
import { ManagerContext } from '../../context/ManagerContext';
import { maskTEL } from '../../utils/mask';

export default function Contact() {
    const { manager } = React.useContext(ManagerContext);
    return (
        <Box sx={{ width: "100vw", height: "100vh", px: 0, m: 0 }}>
            <AppNav />
            <Grid container my={20} columns={12} sx={{ justifyContent: 'center' }}>
                <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>
                    <div>
                        <Typography variant='h4'>Entre em contato</Typography>
                        <Typography variant='body'>Nossos canais de comunicação</Typography>
                    </div>
                </Grid>
                <Grid item pt={4} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <a style={{ textDecoration: 'none', color: 'black' }} href={`mailto:${manager.email}`}>
                        <Typography variant='h6' > Email: {manager.email}</Typography>
                    </a>
                </Grid>
                <Grid item pt={1} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <a style={{ textDecoration: 'none', color: 'black' }} href={`https://api.whatsapp.com/send/?phone=55${manager.whatsapp}`}>
                        <Typography variant='h6' > WhatsApp: {maskTEL(manager.whatsapp)}</Typography>
                    </a>
                </Grid>
            </Grid>
        </Box>
    )
}