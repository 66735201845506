import React from 'react';
import { api } from '../../../services/api'
import { useParams } from "react-router-dom";
import { Stack, Alert } from '@mui/material';
import { maskCNPJ, maskCEP, maskCPF, linhaDigitavel, parseData } from '../../../utils/mask';
import QRCode from 'qrcode';
import JsBarcode from "jsbarcode";

export default function BoletoItau() {
    const [htmlContent, setHtmlContent] = React.useState("");
    const { externalId } = useParams();
    const [error, setError] = React.useState(false)
    
    
    React.useEffect(() => {
        async function loadData() {
            try {
                console.log(externalId)
                const response = await api.get(`/order/boleto/${externalId}`)
                console.log(response)
                await loadBolecode(response)
            } catch (error) {
                setError(true)
                console.log(error)

            }
        }
        async function loadBolecode(response) {
            await fetch("/bolecode/index.html")
                .then((response) => response.text())
                .then(async (html) => {
                    const value = new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format((parseInt(response.data.bolecode.data.dado_boleto.valor_total_titulo, 10) / 100).toFixed(2))
                    const QRpix = await QRCode.toDataURL(response.data.bolecode.data.dados_qrcode.emv);
                    const canvas = document.createElement("canvas");
                    JsBarcode(canvas, response.data.bolecode.data.dado_boleto.dados_individuais_boleto[0].codigo_barras, { format: "CODE128", displayValue: false, });
                    const buffer = canvas.toDataURL("image/png");
                    const Codebar = buffer.toString('base64');
                    const dadosBoleto = {
                        LINHA_DIGITAVEL: linhaDigitavel(response.data.bolecode.data.dado_boleto.dados_individuais_boleto[0].numero_linha_digitavel),
                        DATA_VENCIMENTO: parseData(response.data.bolecode.data.dado_boleto.dados_individuais_boleto[0].data_vencimento),
                        NOME_BENEFICIARIO: response.data.bolecode.data.beneficiario.nome_cobranca,
                        CNPJ_BENEFICIARIO: maskCNPJ(response.data.bolecode.data.beneficiario.tipo_pessoa.numero_cadastro_nacional_pessoa_juridica),
                        LOGRADOURO_BENEFICIARIO: response.data.bolecode.data.beneficiario.endereco.nome_logradouro,
                        BAIRRO_BENEFICIARIO: response.data.bolecode.data.beneficiario.endereco.nome_bairro,
                        CIDADE_BENEFICIARIO: response.data.bolecode.data.beneficiario.endereco.nome_cidade,
                        UF_BENEFICIARIO: response.data.bolecode.data.beneficiario.endereco.sigla_UF,
                        CEP_BENEFICIARIO: maskCEP(response.data.bolecode.data.beneficiario.endereco.numero_CEP),
                        DATA_BOLETO: parseData(response.data.bolecode.data.dado_boleto.data_emissao),
                        NUMERO_BOLETO: response.data.external_id,
                        PRCOCESSAMENTO_BOLETO: parseData(response.data.bolecode.data.dado_boleto.data_emissao),
                        NOSSO_NUMERO_BOLETO: response.data.bolecode.data.dado_boleto.dados_individuais_boleto[0].numero_nosso_numero,
                        VALOR_BOLETO: value,
                        NOME_EVENTO: String(response.data.event.name).substring(0, 50),
                        DOCUMENTO_INSCRITO: maskCPF(response.data.subscribers[0].document),
                        NOME_INSCRITO: response.data.subscribers[0].name,
                        NOME_PAGADOR: String(response.data.bolecode.data.dado_boleto.pagador.pessoa.nome_pessoa).substring(0, 40),
                        DOCUMENTO_PAGADOR: maskCNPJ(response.data.bolecode.data.dado_boleto.pagador.pessoa.tipo_pessoa.numero_cadastro_nacional_pessoa_juridica),
                        LOGRADOURO_PAGADOR: response.data.bolecode.data.dado_boleto.pagador.endereco.nome_logradouro,
                        BAIRRO_PAGADOR: response.data.bolecode.data.dado_boleto.pagador.endereco.nome_bairro,
                        CIDADE_PAGADOR: response.data.bolecode.data.dado_boleto.pagador.endereco.nome_cidade,
                        UF_PAGADOR: response.data.bolecode.data.dado_boleto.pagador.endereco.sigla_UF,
                        CEP_PAGADOR: maskCEP(response.data.bolecode.data.dado_boleto.pagador.endereco.numero_CEP),
                        DAC_TITULO: response.data.bolecode.data.dado_boleto.dados_individuais_boleto[0].dac_titulo || 'undefined',
                        CODIGOPIX_BOLETO: QRpix,
                        CODIGOBARRAS_BOLETO: Codebar
                    };
                    console.log({ dadosBoleto })
                    const renderedHtml = html.replace(/{{(.*?)}}/g, (_, key) => dadosBoleto[key.trim()] || "");

                    setHtmlContent(renderedHtml);
                });

        }
        loadData()


    }, []);



    return (
        <>
            {error ? <Stack
                sx={{ display: 'flex', width: '100%', height: '90%', justifyContent: 'center' }}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}>
                <Alert sx={{ width: '50%' }} severity="error">Erro ao carregar Boleto! Tente novamente mais tarde...</Alert>
            </Stack>
                : <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
        </>
    )
}