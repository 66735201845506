import React from 'react'

export const ManagerContext = React.createContext({
    manager: [{ 
        document: String,
        email: String,
        bank: String,
        fantasy: String,
        whatsapp: String,
        agency: String,
        operation: String,
        social_reason: String,
        cc: String,
        date_payment: String,
        logo: String,
        config_path: String,    
    }],
    setManager: () => {}
});

export function ManagerContextProvider({children}) {
   const [manager, setManager] = React.useState([])


   return <ManagerContext.Provider value={{manager,setManager}}>{children}</ManagerContext.Provider>
 }