/* eslint-disable react-hooks/rules-of-hooks */
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { ManagerContext } from "../context/ManagerContext";


const pages = [{ page: "Eventos", link: "/" }, { page: "Certidões", link: '/documents' }, { page: "Contato", link: "/contact" }, { page: "Eventos Anteriores", link: "/eventos-anteriores" }]

const appNav = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  let navigate = useNavigate()
  const location = useLocation();
  const { manager } = React.useContext(ManagerContext);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (link) => {
    navigate(`${link}`)
    setAnchorElNav(null);
  };



  return (
    <AppBar position="sticky" sx={{ backgroundColor: "#1d385e", m: 0, p: 0 }}>
      <Box >
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button sx={{ display: { xs: 'none', md: 'flex' } }} href={manager.config_path}>
            <img
              src={manager.logo_url}
              alt="Logo app"
              style={{ display: 'flex', width: '55px' }}
            />
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.page} onClick={() => handleCloseNavMenu(page.link)}>
                  <Typography textAlign="center">{page.page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: { md: 'center' } }}>
            <img
              src={manager.logo_url}
              alt="Logo app"
              style={{ display: 'flex', width: '55px' }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: { md: 'center' } }}>
            {pages.map((page) => (
              <Button
                key={page.page}
                onClick={() => handleCloseNavMenu(page.link)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default appNav;