import axios from "axios";

const getApiBaseUrl = () => {
   const pathSegments = window.location.pathname.split("/").filter(Boolean);
   const prefix = pathSegments[0]; 
   console.log(pathSegments, prefix )
 
   switch (prefix) {
     case "apeprev":
       return "https://apeprev.sempereventos.com.br"; 
     default:
       return "https://api.sempereventos.com.br";
   }
 };

export const baseURL = getApiBaseUrl();

export const api = axios.create({
  baseURL: baseURL+'/api',
});
